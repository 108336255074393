var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('h3',[_vm._v(" หน้าแสดงข้อมูลจัดการข่าว")]),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","outlined":"","placeholder":"ค้นหา"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-col',{staticStyle:{"text-align":"end"},attrs:{"cols":"6","right":""}},[_c('v-btn',{staticStyle:{"color":"white"},attrs:{"color":"#744D9c"},on:{"click":function($event){return _vm.gotoadd()}}},[_vm._v("เพิ่มข่าว")])],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-4",attrs:{"headers":_vm.headers,"items":_vm.list,"search":_vm.search,"items-per-page":10,"loading":_vm.loading,"loading-text":"กำลังโหลดข้อมูล...กรุณารอสักครู่"},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-icon',{on:{"click":function($event){return _vm.deleteData(item)}}},[_vm._v("mdi-delete")])],1)]}},{key:"item.imgUrl",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"src":item.imgUrl,"height":"50px"}})]}},{key:"item.activeFlag",fn:function(ref){
var item = ref.item;
return [(item.activeFlag == true)?_c('span',[_vm._v("ใช้งาน")]):_c('span',[_vm._v("ไม่ใช้งาน")])]}},{key:"item.count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.count)+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }