<template>
  <v-card>
    <v-container>
      <h3> หน้าแสดงข้อมูลจัดการข่าว</h3>
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="search"
            dense
            hide-details
            outlined
            placeholder="ค้นหา"
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer> 
        <v-col cols="6" right style="text-align: end;"
          ><v-btn color="#744D9c" style="color:white;" @click="gotoadd()"
            >เพิ่มข่าว</v-btn
          ></v-col
        >
      </v-row>
      <v-data-table
        :headers="headers"
        :items="list"
        :search="search"
        :items-per-page="10"
        class="elevation-1 mt-4"
        :loading="loading"
        loading-text="กำลังโหลดข้อมูล...กรุณารอสักครู่"
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-row justify="center">
            <v-icon @click="deleteData(item)">mdi-delete</v-icon>
            <!-- <v-icon @click="Editdata(item)">mdi-magnify</v-icon> -->
          </v-row>
        </template>
        <template v-slot:[`item.imgUrl`]="{ item }">
          <!-- {{ item.imgUrl | truncate(24, "...") }} -->
          <img :src="item.imgUrl" height="50px" />
        </template>
        <template v-slot:[`item.activeFlag`]="{ item }">
          <span v-if="item.activeFlag == true">ใช้งาน</span>
          <span v-else>ไม่ใช้งาน</span>
        </template>
         <template v-slot:[`item.count`]="{ item }">
          {{ item.count }}
        </template>
      </v-data-table>
    </v-container>
  </v-card>
</template>
<script>
import axios from "axios";
import { Decode } from '@/services'
export default {
    filters: {
    truncate: function (value, limit) {
      if (value.length > limit) {
        value = value.substring(0, limit - 4) + "...";
      }
      return value;
    },
  },
  data() {
    return {
      search: "",
      loading: true,
      headers: [
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "หัวข้อ", value: "title", align: "center" },
        { text: "รายละเอียด", value: "detail", align: "center" },
        { text: "รูปภาพ", value: "imgUrl", align: "center" },
        { text: "สถานะ", value: "activeFlag", align: "center" },
        // { text: "6", value: "createdAt", align: "center" },
        // { text: "7", value: "updatedAt", align: "center" },
        { text: "การจัดการ", value: "action", align: "center" },
      ],
      list: [],
      count: 0,
      checkuser: []
    };
  },
  created() {
    this.checkuser = JSON.parse(Decode.decode((localStorage.getItem("user"))));
    this.getAllnews();
  },

  methods: {
    gotoadd(){
     this.$router.push("/addnews");
  },
     async deleteData(val) {
      const Id = val.id;
      console.log("delete", Id);
      // this.$swal("Hello Vue world!!!");
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
            const auth = {
            headers: { Authorization: `Bearer` + " " + this.checkuser.token },
          };

          await axios.delete(`${process.env.VUE_APP_API}/news/` + Id, auth);
          this.$swal.fire({
            icon: "success",
            text: "Delete Success",
            showConfirmButton: false,
            timer: 1000,
          });
          await this.getAllnews();
        }
      });
    },
    async getAllnews() {
      // console.log(token);
      const auth = {
        headers: { Authorization: `Bearer` + " " + this.checkuser.token },
      };

      const response = await axios.get(`${process.env.VUE_APP_API}/news`, auth);
      console.log("ดาต้าที่ออกมา", response.data.data);
      this.list = response.data.data;
      for (let i in this.list) {
        this.list[i].count = parseInt(i) + 1;
        // console.log(this.listdata);
      }
      this.loading = false
    },
  },
};
</script>
<style scoped>
.f-right {
  float: right;
}
</style>
